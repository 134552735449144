.App {
  text-align: center;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h2 {
  font-family: "PontiacBlack";
}

button {
  background-color: #04AA6D; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 20px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 1em;
}

.App-background {
  background-color: #282c34;
  min-height: 100vh;
  /* max-height: 100vh; */
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow: hidden;
  position: relative;
  /* background-image: url("C:/Users/Janneke/Documents/Persoonlijk/SnapFrame/client/src/58e915beeb97430e819064f2.png") */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* grid home-page */
.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  /* background-color: #2196F3; */
  padding-left: 5em;
  padding-right: 5em;
  padding-top: 1em;
}

.grid-item {
  /* background-color: #5171FF; */
  background-color: #53565d;
  /* border: 1px solid rgba(0, 0, 0, 0.8); */
  border-radius: 5px;
  padding: 20px;
  margin: 5px;
  font-size: 30px;
  text-align: center;
}

.grid-container div:hover {
  opacity: 0.6;
  /* background-color: blue; */
  cursor: pointer;
}

.grid-container div:active {
  opacity: 0.6;
  /* background-color: blue; */
  cursor: pointer;
}

  .spinnerbg {
    width: 100vw;
    height: 100vh;
    background-color: #282c34;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .spinner {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;

  }

  
@keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    background-color: #282C34;
    border-radius: 10px;
    /* table-layout: fixed; */
  }
  
  td, th {
    /* border: 1px solid #282C34; */
    text-align: left;
    padding: 8px;
  }
  
  th {
    background-color: #282C34;
    border-radius: 10px;
  }
  
  tr:nth-child(odd) {
    background-color: #20232a;
  }

  .logoutbtn {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #53565d;
  }

  .logoutbtn:hover {
    cursor: pointer;
  }

  .modal-wrap {
    overflow: hidden;
    position: relative;
  }

  .modal {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(40, 44, 52, 0.9);
    z-index: 99;
    /* align-items: center; */
    display: flex;
    justify-content: center;
  }

  .modal-content {
    display: flex;
    /* width: 60%;
    background-color: blue;
    border-radius: 10px;
    padding: 10px;
    position: relative; */
  }

  @media only screen and (max-width: 600px) {
    .modal-content {
      /* width: 80%; */
    }
  }